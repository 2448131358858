import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import { legalNoticeStyles } from './styles';

const LegalNotice = () => {
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );
  const pluginImage = getImage(backgroundImagePrices);

  // const LegalNotice = () => {
  const classes = legalNoticeStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesNoContent}>
              <Typography className={classes.siteTitle}>{/* {t('trans.privacyPolicyHead')} */}</Typography>
            </Box>
          </Grid>
        </Grid>
      </BgImage>
      {/* <Box className={classes.boxoben} /> */}

      <Box className={classes.box}>
        <h1 className="adsimple-321244783">Impressum</h1>
        <p className="adsimple-321244783">Informationspflicht laut § 5 TMG.</p>
        <p className="adsimple-321244783">
          Hirten Technologies
          <br />
          Augustastraße 1 <br />
          57555 Mudersbach <br />
          Deutschland
        </p>
        <p className="adsimple-321244783" />
        <p className="adsimple-321244783">
          <strong>Tel.:</strong> 0151 473 893 30
          <br />
          <strong>E-Mail:</strong> <a href="mailto:info@d-simple.com">info@d-simple.com</a>
        </p>

        <h2 className="adsimple-321244783">EU-Streitschlichtung</h2>
        <p>
          Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
          über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
          <br />
          Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
          Kommission unter{' '}
          <a
            className="adsimple-321244783"
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
            target="_blank"
            rel="noreferrer"
          >
            http://ec.europa.eu/odr?tid=321244783
          </a>{' '}
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
        </p>
        <p>
          Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2 className="adsimple-321244783">Haftung für Inhalte dieser Website</h2>
        <p>
          Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
          bereitzustellen. Laut Telemediengesetz{' '}
          <a
            className="adsimple-321244783"
            href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321244783"
            rel="noreferrer"
            target="_blank"
          >
            (TMG) §7 (1)
          </a>{' '}
          sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen
          Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
          übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8
          bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen
          oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach
          den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle
          unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.{' '}
        </p>
        <p>
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren,
          damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
        </p>
        <h2 className="adsimple-321244783">Haftung für Links auf dieser Website</h2>
        <p>
          Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
          verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
          uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn
          uns Rechtswidrigkeiten bekannt werden.
        </p>
        <p>
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden
          die Kontaktdaten im Impressum.
        </p>
        <h2 className="adsimple-321244783">Urheberrechtshinweis</h2>
        <p>
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik
          Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder
          verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die
          unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
        </p>
        <p>
          Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
          kontaktieren.
        </p>
        <h2 className="adsimple-321244783">Bildernachweis</h2>
        <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
        <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
        <ul className="adsimple-321244783">
          <p className="adsimple-321244783">
            Hirten Technologies
            <br />
            Augustastraße 1 <br />
            57555 Mudersbach <br />
            Deutschland
          </p>
          <p className="adsimple-321244783" />
        </ul>
      </Box>
    </>
  );
};

export default LegalNotice;
